/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { RiArrowRightSLine, RiFileListLine, RiUserSettingsLine, RiTimeLine, RiShieldCheckLine, RiHandHeartLine, RiParentLine } from "react-icons/ri"

const PoliciesPage = () => {
  return (
    <Layout>
      <SEO 
        title="HR Policy Templates | Compliant HR Documentation"
        description="Download customizable HR policies and procedures templates or get custom-tailored policy development services for your specific organizational requirements and industry needs."
        keywords={[
          "HR policy templates",
          "employee handbook templates",
          "workplace policies",
          "HR compliance documents",
          "policy guidelines",
          "custom HR policies",
          "tailored policy development"
        ]}
      />
      
      <div sx={{
        bg: 'primaryMuted',
        pt: [4, 5],
        pb: [4, 5]
      }}>
        <div sx={{
          maxWidth: '1200px',
          mx: 'auto',
          px: 3
        }}>
          <h1 sx={{
            fontSize: ['2rem', '2.5rem'],
            fontWeight: 700,
            textAlign: 'center',
            mb: 3,
            color: 'primary'
          }}>
            HR Policy Templates & Resources
          </h1>
          
          <p sx={{
            fontSize: '1.1rem',
            textAlign: 'center',
            maxWidth: '800px',
            mx: 'auto',
            mb: 5,
            lineHeight: 1.5,
            color: 'text'
          }}>
            Downloadable, customizable policy templates to help you build compliant, 
            comprehensive HR documentation for your organization. We also offer custom policy 
            development services tailored to your specific requirements and industry needs.
          </p>
          
          <div sx={{
            display: 'grid',
            gridTemplateColumns: ['1fr', 'repeat(2, 1fr)', 'repeat(3, 1fr)'],
            gap: 4,
            mb: 5
          }}>
            {/* Employee Handbook Template */}
            <div sx={{
              bg: 'white',
              p: 3,
              borderRadius: '8px',
              boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
              transition: 'transform 0.2s ease, box-shadow 0.2s ease',
              '&:hover': {
                transform: 'translateY(-3px)',
                boxShadow: '0 6px 16px rgba(0,0,0,0.08)'
              }
            }}>
              <Link to="/policies/employee-handbook" sx={{
                textDecoration: 'none',
                color: 'text'
              }}>
                <div sx={{
                  color: 'primary',
                  fontSize: '1.75rem',
                  mb: 2
                }}>
                  <RiFileListLine />
                </div>
                <h3 sx={{
                  fontSize: '1.25rem',
                  fontWeight: 600,
                  mb: 1,
                  color: 'text'
                }}>
                  Employee Handbook
                </h3>
                <p sx={{
                  fontSize: '0.95rem',
                  color: 'text',
                  opacity: 0.8,
                  lineHeight: 1.5,
                  mb: 2
                }}>
                  Comprehensive template covering all essential workplace policies and procedures.
                </p>
                <div sx={{
                  display: 'flex',
                  alignItems: 'center',
                  color: 'primary',
                  fontWeight: 600,
                  fontSize: '0.95rem'
                }}>
                  Get Template <RiArrowRightSLine sx={{ ml: 1 }} />
                </div>
              </Link>
            </div>
            
            {/* Performance Management Policy */}
            <div sx={{
              bg: 'white',
              p: 3,
              borderRadius: '8px',
              boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
              transition: 'transform 0.2s ease, box-shadow 0.2s ease',
              '&:hover': {
                transform: 'translateY(-3px)',
                boxShadow: '0 6px 16px rgba(0,0,0,0.08)'
              }
            }}>
              <Link to="/policies/performance-management" sx={{
                textDecoration: 'none',
                color: 'text'
              }}>
                <div sx={{
                  color: 'primary',
                  fontSize: '1.75rem',
                  mb: 2
                }}>
                  <RiUserSettingsLine />
                </div>
                <h3 sx={{
                  fontSize: '1.25rem',
                  fontWeight: 600,
                  mb: 1,
                  color: 'text'
                }}>
                  Performance Management
                </h3>
                <p sx={{
                  fontSize: '0.95rem',
                  color: 'text',
                  opacity: 0.8,
                  lineHeight: 1.5,
                  mb: 2
                }}>
                  Guidelines for conducting reviews, setting goals, and managing underperformance.
                </p>
                <div sx={{
                  display: 'flex',
                  alignItems: 'center',
                  color: 'primary',
                  fontWeight: 600,
                  fontSize: '0.95rem'
                }}>
                  Get Template <RiArrowRightSLine sx={{ ml: 1 }} />
                </div>
              </Link>
            </div>
            
            {/* Leave Policy */}
            <div sx={{
              bg: 'white',
              p: 3,
              borderRadius: '8px',
              boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
              transition: 'transform 0.2s ease, box-shadow 0.2s ease',
              '&:hover': {
                transform: 'translateY(-3px)',
                boxShadow: '0 6px 16px rgba(0,0,0,0.08)'
              }
            }}>
              <Link to="/policies/leave-policy" sx={{
                textDecoration: 'none',
                color: 'text'
              }}>
                <div sx={{
                  color: 'primary',
                  fontSize: '1.75rem',
                  mb: 2
                }}>
                  <RiTimeLine />
                </div>
                <h3 sx={{
                  fontSize: '1.25rem',
                  fontWeight: 600,
                  mb: 1,
                  color: 'text'
                }}>
                  Leave & Time Off
                </h3>
                <p sx={{
                  fontSize: '0.95rem',
                  color: 'text',
                  opacity: 0.8,
                  lineHeight: 1.5,
                  mb: 2
                }}>
                  Vacation, sick leave, personal days, and other time off policies.
                </p>
                <div sx={{
                  display: 'flex',
                  alignItems: 'center',
                  color: 'primary',
                  fontWeight: 600,
                  fontSize: '0.95rem'
                }}>
                  Get Template <RiArrowRightSLine sx={{ ml: 1 }} />
                </div>
              </Link>
            </div>
            
            {/* Workplace Safety */}
            <div sx={{
              bg: 'white',
              p: 3,
              borderRadius: '8px',
              boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
              transition: 'transform 0.2s ease, box-shadow 0.2s ease',
              '&:hover': {
                transform: 'translateY(-3px)',
                boxShadow: '0 6px 16px rgba(0,0,0,0.08)'
              }
            }}>
              <Link to="/policies/workplace-safety" sx={{
                textDecoration: 'none',
                color: 'text'
              }}>
                <div sx={{
                  color: 'primary',
                  fontSize: '1.75rem',
                  mb: 2
                }}>
                  <RiShieldCheckLine />
                </div>
                <h3 sx={{
                  fontSize: '1.25rem',
                  fontWeight: 600,
                  mb: 1,
                  color: 'text'
                }}>
                  Workplace Safety
                </h3>
                <p sx={{
                  fontSize: '0.95rem',
                  color: 'text',
                  opacity: 0.8,
                  lineHeight: 1.5,
                  mb: 2
                }}>
                  Health and safety procedures, emergency protocols, and incident reporting.
                </p>
                <div sx={{
                  display: 'flex',
                  alignItems: 'center',
                  color: 'primary',
                  fontWeight: 600,
                  fontSize: '0.95rem'
                }}>
                  Get Template <RiArrowRightSLine sx={{ ml: 1 }} />
                </div>
              </Link>
            </div>
            
            {/* Diversity & Inclusion */}
            <div sx={{
              bg: 'white',
              p: 3,
              borderRadius: '8px',
              boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
              transition: 'transform 0.2s ease, box-shadow 0.2s ease',
              '&:hover': {
                transform: 'translateY(-3px)',
                boxShadow: '0 6px 16px rgba(0,0,0,0.08)'
              }
            }}>
              <Link to="/policies/diversity-inclusion" sx={{
                textDecoration: 'none',
                color: 'text'
              }}>
                <div sx={{
                  color: 'primary',
                  fontSize: '1.75rem',
                  mb: 2
                }}>
                  <RiHandHeartLine />
                </div>
                <h3 sx={{
                  fontSize: '1.25rem',
                  fontWeight: 600,
                  mb: 1,
                  color: 'text'
                }}>
                  Diversity & Inclusion
                </h3>
                <p sx={{
                  fontSize: '0.95rem',
                  color: 'text',
                  opacity: 0.8,
                  lineHeight: 1.5,
                  mb: 2
                }}>
                  Policies to promote a diverse, equitable, and inclusive workplace.
                </p>
                <div sx={{
                  display: 'flex',
                  alignItems: 'center',
                  color: 'primary',
                  fontWeight: 600,
                  fontSize: '0.95rem'
                }}>
                  Get Template <RiArrowRightSLine sx={{ ml: 1 }} />
                </div>
              </Link>
            </div>
            
            {/* Family Leave */}
            <div sx={{
              bg: 'white',
              p: 3,
              borderRadius: '8px',
              boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
              transition: 'transform 0.2s ease, box-shadow 0.2s ease',
              '&:hover': {
                transform: 'translateY(-3px)',
                boxShadow: '0 6px 16px rgba(0,0,0,0.08)'
              }
            }}>
              <Link to="/policies/family-leave" sx={{
                textDecoration: 'none',
                color: 'text'
              }}>
                <div sx={{
                  color: 'primary',
                  fontSize: '1.75rem',
                  mb: 2
                }}>
                  <RiParentLine />
                </div>
                <h3 sx={{
                  fontSize: '1.25rem',
                  fontWeight: 600,
                  mb: 1,
                  color: 'text'
                }}>
                  Family & Parental Leave
                </h3>
                <p sx={{
                  fontSize: '0.95rem',
                  color: 'text',
                  opacity: 0.8,
                  lineHeight: 1.5,
                  mb: 2
                }}>
                  Maternity, paternity, adoption, and family care leave policies.
                </p>
                <div sx={{
                  display: 'flex',
                  alignItems: 'center',
                  color: 'primary',
                  fontWeight: 600,
                  fontSize: '0.95rem'
                }}>
                  Get Template <RiArrowRightSLine sx={{ ml: 1 }} />
                </div>
              </Link>
            </div>
          </div>
          
          {/* Custom Policy Development Section */}
          <div sx={{
            bg: 'primaryMuted',
            p: 4,
            borderRadius: '8px',
            boxShadow: '0 4px 12px rgba(0,0,0,0.08)',
            maxWidth: '900px',
            mx: 'auto',
            mb: 5,
            textAlign: 'center'
          }}>
            <h2 sx={{
              fontSize: ['1.5rem', '1.75rem'],
              fontWeight: 700,
              mb: 3,
              color: 'primary'
            }}>
              Custom Policy Development
            </h2>
            
            <p sx={{
              fontSize: ['1rem', '1.1rem'],
              color: 'text',
              mb: 4,
              lineHeight: 1.6,
              maxWidth: '750px',
              mx: 'auto'
            }}>
              Beyond our templates, we also develop custom and tailored policies specific to your organization's 
              unique requirements, industry regulations, and company culture.
            </p>
            
            <div sx={{
              display: 'grid',
              gridTemplateColumns: ['1fr', 'repeat(2, 1fr)', 'repeat(3, 1fr)'],
              gap: 3,
              textAlign: 'left',
              mb: 4
            }}>
              <div sx={{
                bg: 'white',
                p: 3,
                borderRadius: '6px',
                boxShadow: '0 2px 6px rgba(0,0,0,0.05)'
              }}>
                <h4 sx={{
                  fontSize: '1.1rem',
                  fontWeight: 600,
                  mb: 2,
                  color: 'primary',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2
                }}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" sx={{ color: 'primary' }}>
                    <title>Industry icon</title>
                    <path d="M2 20h20" />
                    <path d="M5 8h3a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1z" />
                    <path d="M16 7h3a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1h-3a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1z" />
                    <path d="M5 14h3a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1z" />
                    <path d="M16 13h3a1 1 0 0 0 1-1V8a1 1 0 0 0-1-1h-3a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1z" />
                    <path d="M4 8v12" />
                    <path d="M16 19v-7" />
                    <path d="M9 19v-5" />
                    <path d="M20 19V8" />
                  </svg>
                  Industry-Specific
                </h4>
                <p sx={{ fontSize: '0.95rem', mb: 0, lineHeight: 1.5 }}>
                  Policies tailored to your industry's unique requirements and regulatory landscape.
                </p>
              </div>
              
              <div sx={{
                bg: 'white',
                p: 3,
                borderRadius: '6px',
                boxShadow: '0 2px 6px rgba(0,0,0,0.05)'
              }}>
                <h4 sx={{
                  fontSize: '1.1rem',
                  fontWeight: 600,
                  mb: 2,
                  color: 'primary',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2
                }}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" sx={{ color: 'primary' }}>
                    <title>Size icon</title>
                    <rect x="3" y="3" width="18" height="18" rx="2" />
                    <path d="M9 15v-6l7.5 6v-6" />
                  </svg>
                  Size-Appropriate
                </h4>
                <p sx={{ fontSize: '0.95rem', mb: 0, lineHeight: 1.5 }}>
                  Solutions scaled for organizations of all sizes, from startups to enterprise businesses.
                </p>
              </div>
              
              <div sx={{
                bg: 'white',
                p: 3,
                borderRadius: '6px',
                boxShadow: '0 2px 6px rgba(0,0,0,0.05)'
              }}>
                <h4 sx={{
                  fontSize: '1.1rem',
                  fontWeight: 600,
                  mb: 2,
                  color: 'primary',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2
                }}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" sx={{ color: 'primary' }}>
                    <title>Culture icon</title>
                    <path d="M18 3a3 3 0 0 0-3 3v12a3 3 0 0 0 3 3 3 3 0 0 0 3-3 3 3 0 0 0-3-3H6a3 3 0 0 0-3 3 3 3 0 0 0 3 3 3 3 0 0 0 3-3V6a3 3 0 0 0-3-3 3 3 0 0 0-3 3 3 3 0 0 0 3 3h12a3 3 0 0 0 3-3 3 3 0 0 0-3-3z" />
                  </svg>
                  Culture-Aligned
                </h4>
                <p sx={{ fontSize: '0.95rem', mb: 0, lineHeight: 1.5 }}>
                  Policies that reflect and reinforce your organization's unique values and culture.
                </p>
              </div>
            </div>
            
            <Link to="/services/custom-policy-development" sx={{
              display: 'inline-flex',
              alignItems: 'center',
              gap: 2,
              bg: 'white',
              color: 'primary',
              py: 2,
              px: 4,
              borderRadius: '6px',
              fontSize: '1rem',
              fontWeight: 600,
              textDecoration: 'none',
              boxShadow: '0 2px 6px rgba(0,0,0,0.1)',
              transition: 'transform 0.2s ease, box-shadow 0.2s ease',
              '&:hover': {
                transform: 'translateY(-2px)',
                boxShadow: '0 4px 10px rgba(0,0,0,0.15)'
              }
            }}>
              Learn About Our Custom Policy Solutions <RiArrowRightSLine />
            </Link>
          </div>
          
          <div sx={{
            bg: 'white',
            p: 4,
            borderRadius: '8px',
            boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
            maxWidth: '800px',
            mx: 'auto'
          }}>
            <h3 sx={{
              fontSize: '1.25rem',
              fontWeight: 600,
              mb: 2,
              color: 'primary'
            }}>
              Need Policy Customization?
            </h3>
            <p sx={{
              fontSize: '0.95rem',
              color: 'text',
              mb: 3,
              lineHeight: 1.5
            }}>
              Our HR consultants can work directly with your team to develop completely tailored policies or customize our templates to address your organization's specific needs, industry requirements, and company values while ensuring full regulatory compliance.
            </p>
            <div sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: 3,
              justifyContent: ['center', 'flex-start']
            }}>
              <Link to="/contact" sx={{
                display: 'inline-flex',
                alignItems: 'center',
                gap: 2,
                bg: 'primary',
                color: 'white',
                py: 2,
                px: 3,
                borderRadius: '6px',
                fontSize: '0.95rem',
                fontWeight: 600,
                textDecoration: 'none',
                transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                '&:hover': {
                  transform: 'translateY(-2px)',
                  boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                  color: 'white'
                }
              }}>
                Request a Consultation <RiArrowRightSLine />
              </Link>
              
              <Link to="/services/custom-policy-development" sx={{
                display: 'inline-flex',
                alignItems: 'center',
                gap: 2,
                bg: 'transparent',
                color: 'primary',
                py: 2,
                px: 3,
                borderRadius: '6px',
                fontSize: '0.95rem',
                fontWeight: 600,
                textDecoration: 'none',
                border: '1px solid',
                borderColor: 'primary',
                transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                '&:hover': {
                  transform: 'translateY(-2px)',
                  boxShadow: '0 4px 8px rgba(0,0,0,0.05)'
                }
              }}>
                Learn About Our Process <RiArrowRightSLine />
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* FAQ Section */}
      <div sx={{
        maxWidth: '800px',
        mx: 'auto',
        py: 5,
        px: [3, 0]
      }}>
        <h2 sx={{ 
          fontSize: ['1.5rem', '1.65rem'],
          mb: 4,
          color: 'primary',
          textAlign: 'center'
        }}>Frequently Asked Questions About HR Policies</h2>

        <div sx={{
          display: 'grid',
          gap: 4
        }}>
          <div>
            <h3 sx={{ 
              fontSize: ['1.1rem', '1.2rem'],
              color: 'primary',
              mb: 2
            }}>Why are written HR policies important for my business?</h3>
            <p sx={{ 
              fontSize: '1rem',
              color: 'text',
              lineHeight: 1.6
            }}>Clearly defined HR policies are crucial for setting expectations, ensuring fair treatment of employees, maintaining compliance with labor laws, mitigating legal risks, and fostering a positive and productive work environment. They provide a framework for consistent decision-making.</p>
          </div>

          <div>
            <h3 sx={{ 
              fontSize: ['1.1rem', '1.2rem'],
              color: 'primary',
              mb: 2
            }}>Are these policy templates legally compliant?</h3>
            <p sx={{ 
              fontSize: '1rem',
              color: 'text',
              lineHeight: 1.6
            }}>Our templates are designed based on general best practices and common legal requirements. However, laws vary by location (state/country) and industry. We strongly recommend having any policy reviewed by legal counsel familiar with your specific jurisdiction and business context before implementation.</p>
          </div>

          <div>
            <h3 sx={{ 
              fontSize: ['1.1rem', '1.2rem'],
              color: 'primary',
              mb: 2
            }}>Can I customize these templates?</h3>
            <p sx={{ 
              fontSize: '1rem',
              color: 'text',
              lineHeight: 1.6
            }}>Absolutely! Our templates are provided in editable formats (like Word or Google Docs) specifically so you can customize them to fit your company culture, size, industry, and specific operational needs. Remember to tailor the language and specifics accordingly.</p>
          </div>

          <div>
            <h3 sx={{ 
              fontSize: ['1.1rem', '1.2rem'],
              color: 'primary',
              mb: 2
            }}>How often should we review and update our HR policies?</h3>
            <p sx={{ 
              fontSize: '1rem',
              color: 'text',
              lineHeight: 1.6
            }}>It's best practice to review your HR policies at least annually, or more frequently if there are significant changes in labor laws, regulations, your business operations, or company structure. Keeping policies current is key to maintaining compliance and relevance.</p>
          </div>

          <div>
            <h3 sx={{ 
              fontSize: ['1.1rem', '1.2rem'],
              color: 'primary',
              mb: 2
            }}>What if I need a policy that isn't listed here?</h3>
            <p sx={{ 
              fontSize: '1rem',
              color: 'text',
              lineHeight: 1.6
            }}>If you require a specific policy not covered by our templates, we offer custom policy development services. Our HR consultants can work with you to create tailored policies that meet your unique needs and ensure compliance. Please contact us for a consultation.</p>
          </div>
        </div>
      </div>

    </Layout>
  )
}

export default PoliciesPage 